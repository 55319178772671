<template>
  <!-- 产品管理 -->
  <div class="productManagementPageBox">
    <!-- 搜索筛选 -->
    <FormSearch :add-row="addRow" :getdata="getdata" :reset="true" :form-inline="formInline" :search-form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <Table
      :item-data="itemData"
      :list-data="listData"
      :loading="loading"
      :operation-button="operationButton"
    />
    <!-- 分页 -->
    <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination'
import { inventoryPage } from '@/http/agentApi/common'
export default {
  components: { FormSearch, Table, Pagination },
  data() {
    return {
      cmpNameArr: [],
      editFormData: {},
      itemData: [
        { label: '产品名称', prop: 'inventoryNo', width: 180 },
        { label: '合作厂家', prop: 'warehouseName', width: 180 },
        { label: '保证金比例', prop: 'custName', width: 180 },
        { label: '代采费率', prop: 'goodsName', width: 120 },
        { label: '代采期限(天)', prop: 'materials', width: 120 },
        { label: '结算日期', prop: 'specs', width: 120 },
        { label: '创建时间', prop: 'createTime', width: 140 },
        { label: '状态', prop: 'invWeight', width: 100 }
      ],
      formItemArr: [
        { type: 'input', label: '合作企业', value: 'custName' },
        { type: 'input', label: '产品名称', value: 'goodsName' }
      ],
      loading: false,
      total: 0,
      listData: [],
      operationButton: [],
      formInline: {
        pageSize: 10,
        pageNum: 1
      }
    }
  },
  methods: {
    // 点击添加按钮的时候
    addRow() {
      this.$router.push({ path: '/productManagement/addProduct' })
    },
    // 获取列表数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      this.formInline.custId = JSON.parse(localStorage.getItem('userInfoAgent')).cmpId
      inventoryPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    }
  }
}
</script>
